import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import './sty.css';
import { apihost } from '../../env';

function Login() {
    const navigate = useNavigate();
    const [userID, setUserID] = useState('');
    const [password, setpassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getLogin = async (userid, password) => {
        fetch(`https://smtreports.sudeekha.in/Login_New.aspx`, {
            method: "POST",
            body: JSON.stringify({
                User: userid,
                Pass: password
            })
        })
            .then((response) => response.json())
            .then(data => {
                setIsLoading(false)
                if (data.length === 0) {
                    toast.error('Invalid UserName or Password');
                } else {
                    //here to store redux data
                    // navigate('/users')
                    toast.success('Success')
                }
            })
            .catch((err) => {
                console.error(err);
            });
        // fetch(`${apihost}/api/login`, {
        //         method: "POST",
        //         body: JSON.stringify({
        //             User: userid,
        //             Pass: password
        //         })
        //     })
        //     .then((res) => { return res.json() })
        //     .then((data) => {
        //         console.log(data)
        //     })
        //     .catch((e) => { console.log(e) });
    };


    const dologin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        getLogin(userID, password);
    }

    return (
        <div>
            <ToastContainer />
            <div className='main'>
                <div className='cntr'>
                    <div>
                        <h2 style={{ textAlign: 'center' }}>👋 Welcome Back</h2>
                        <p style={{ textAlign: 'center' }}>Sign in to your account to continue</p>
                        <div className='logform'><br />
                            <div style={{ fontSize: '23px' }}><h2 className='hedundr'>Sig</h2>n In</div>

                            <br /><br />
                            <form>
                                User ID
                                <input type='text' className='loginpt' value={userID} onChange={(e) => { setUserID(e.target.value) }} required autoFocus='ture' />
                                Password
                                <input type='password' className='loginpt' value={password} onChange={(e) => { setpassword(e.target.value) }} required /><br />
                                <button className='logsbmt' type='submit' onClick={dologin}>
                                    {isLoading && (
                                        <div className="overlay">
                                            <CircularProgress className="spinner" />
                                        </div>
                                    )}
                                    Sign In
                                </button>
                            </form><br />
                            <p className='para'>By Signing in you agree to the Terms of Service and Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;