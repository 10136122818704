import './header.css';
import { MenuIcon } from '@mui/icons-material';

const Header = () => {
  return (
    <>
      <div className='heddiv'>
        <h2>ERP</h2>
      </div>
    </>
  );
}

export default Header;